import { useEffect, useRef, useState } from "react";
import axios from "../api";
import monrealeLogo from "../../assets/logo.png";

const useAdvertisingVideos = () => {
  const [data, setData] = useState(null);
  const videoRefs = useRef([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isSingleVideo, setIsSingleVideo] = useState(false);

  useEffect(() => {
    axios
      .get(`https://backendstage.monreale.bbsitalia.com/api/advertising-video`)
      .then((response) => {
        setData(response.data);
        setIsSingleVideo(response.data.rows?.length === 1);
        console.log("data received:", response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (data && data.rows && videoRefs.current.length > 0) {
      const currentVideo = videoRefs.current[currentVideoIndex];
      if (currentVideo) {
        currentVideo.addEventListener("ended", handleVideoEnd);
        currentVideo.play().catch((error) => console.log("Play error:", error));
        console.log("video started, currentVideoIndex:", currentVideoIndex);
      }

      return () => {
        if (currentVideo) {
          currentVideo.removeEventListener("ended", handleVideoEnd);
          currentVideo.pause();
          console.log("video paused, currentVideoIndex:", currentVideoIndex);
        }
      };
    }
  }, [currentVideoIndex, videoRefs, data]);

  const handleVideoEnd = () => {
    if (!isSingleVideo) {
      setCurrentVideoIndex((currentVideoIndex + 1) % data.rows?.length);
    } else {
      const currentVideo = videoRefs.current[currentVideoIndex];
      currentVideo.currentTime = 0;
      currentVideo.play().catch((error) => console.log("Play error:", error));
    }
  };

  return { data, videoRefs, currentVideoIndex };
};

const HeaderTotem = () => {
  const { data, videoRefs, currentVideoIndex } = useAdvertisingVideos();

  return (
    <div
      className={
        "bg-monrealeRed flex flex-wrap h-[20vh] justify-center items-center p-8 relative"
      }
    >
      <div className="flex flex-row justify-center items-center">
        <img
          className={"w-[80px] h-auto m-auto relative z-10"}
          src={monrealeLogo}
          alt="Monreale Logo"
        />
        <div
          style={{
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
            zIndex: 10,
          }}
        >
          <h2
            style={{
              marginTop: "1rem",
            }}
          >
            COMUNE DI MONREALE
          </h2>
          <p className="text-white text-xs mb-0">
            Città Metropolitana di Palermo
          </p>
        </div>
      </div>

      {data &&
        data.rows.map((item, index) => (
          <video
            key={index}
            ref={(element) => (videoRefs.current[index] = element)}
            src={`https://backendstage.monreale.bbsitalia.com${item.video}`}
            autoPlay={index === 0}
            muted
            playsInline
            className="w-full h-full object-cover absolute top-0 left-0 z-auto"
            onCanPlayThrough={() => {
              console.log(`Video ready to play, index: ${index}`);
            }}
            onError={(e) => {
              console.error(`Video error on index ${index}: `, e);
            }}
          />
        ))}
    </div>
  );
};

export default HeaderTotem;
